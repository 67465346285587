import React from "react";
import { Link } from "gatsby";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Mandalas = () => {
  return (
    <div>
      <Layout>
        <Head title="Mandalas" />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1557198976/mandalas/1.jpg"
          alt="Benji Friedman plant mandala digital art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1557198976/mandalas/2.jpg"
          alt="Benji Friedman plant mandala digital art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1557198976/mandalas/3.jpg"
          alt="Benji Friedman plant mandala digital art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1557198976/mandalas/4.jpg"
          alt="Benji Friedman plant mandala digital art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1557198976/mandalas/5.jpg"
          alt="Benji Friedman plant mandala digital art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1641531892/mandalas/WOYU0715.jpg"
          alt="Benji Friedman plant mandala digital art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1641531897/mandalas/QUSS5938.jpg"
          alt="Benji Friedman plant mandala digital art"
        />

        <Link to="/photography">
          <h3>Photography</h3>
        </Link>
        <Link to="/media">
          <h3>Media</h3>
        </Link>
        <Link to="/">
          <h3>Home</h3>
        </Link>
      </Layout>
    </div>
  );
};

export default Mandalas;
